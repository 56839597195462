/**
 * This entire file is necessary because of the way the app runs within an
 * iframe when running within the iOS app.  Every time the app is force closed
 * localStorage and IndexedDB of the iframe get cleared.  This causes the user to
 * get logged out and requires them to log back in every time they launch the app.
 * However,  if we can restore the localStorage or IndexedDB state that firebase
 * leaves before firebase starts running the user's session will be recognized
 * and they will not need to log in.
 *
 * There are a couple of parts to this.  First is the localStorage.html file.  There is
 * an iframe which points to that localStorage.html file and as a child iframe on the
 * domain it can listen to the storage events of the parent frame.  It reports those
 * updates to the app shell by sending a PERSIST_LOCALSTORAGE event to the app shell
 * with the contents of whatever is put in localStorage.  The app shell recieves
 * these events and copies the localStorage updates to the localStorage of the app
 * frame which does persist, even if the app is closed.
 *
 * Then when the app starts, the app shell sends the cached localStorage state to the
 * app so it can be restored.  LocalStorage updates are just copied directly into
 * localStorage, indexedDB is a little more complicated but are essentially restored
 * just as it was found.  It is important that this all happens before you import or
 * require firebase because if the localStorage / IndexedDB state is not present when
 * that runs for the first time the user's session will not be recognized.
 */

import { asyncForEach } from "./helpers/asyncForEach";
import { log } from "@/helpers/logger";

const DB_NAME = "firebaseLocalStorageDb";
const DB_OBJECTSTORE_NAME = "firebaseLocalStorage";
const INDEXED_DB_LOCAL_STORAGE_AUTH_STASH_KEY = "firebaseIndexedDBLocalStorageCache";
const DB_DATA_KEYPATH = "fbase_key";
const DB_VERSION = 1;
const TRANSACTION_RETRY_COUNT = 3;

export type PersistedBlob = Record<string, unknown>;
export type PersistenceValue = PersistedBlob | string;

interface DBObject {
  fbase_key: string;
  value: PersistenceValue;
}

class DBPromise<T> {
  constructor(private readonly request: IDBRequest) {}

  toPromise(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.request.addEventListener("success", () => {
        resolve(this.request.result as T);
      });
      this.request.addEventListener("error", () => {
        reject(this.request.error);
      });
    });
  }
}

function openDatabase(): Promise<IDBDatabase> {
  const request = indexedDB.open(DB_NAME, DB_VERSION);
  return new Promise((resolve, reject) => {
    const tooLongTimeout = setTimeout(reject, 200);

    request.addEventListener("blocked", () => {
      reject(request.error);
    });

    request.addEventListener("error", () => {
      reject(request.error);
    });

    request.addEventListener("upgradeneeded", () => {
      const db = request.result;
      try {
        db.createObjectStore(DB_OBJECTSTORE_NAME, { keyPath: DB_DATA_KEYPATH });
      } catch (e) {
        reject(e);
      }
    });

    request.addEventListener("success", () => {
      const db: IDBDatabase = request.result;
      clearTimeout(tooLongTimeout);
      resolve(db);
    });
  });
}

async function runDatabaseOperationWithRetries<T>(
  operation: (db: IDBDatabase) => Promise<T>
): Promise<T> {
  let numAttempts = 0;
  let db;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      db = await openDatabase();
      return await operation(db);
    } catch (e) {
      if (numAttempts++ > TRANSACTION_RETRY_COUNT) {
        throw e;
      }
      if (db) {
        db.close();
      }
    }
  }
}

function getObjectStore(db: IDBDatabase, isReadWrite?: boolean): IDBObjectStore {
  return db
    .transaction([DB_OBJECTSTORE_NAME], isReadWrite ? "readwrite" : "readonly")
    .objectStore(DB_OBJECTSTORE_NAME);
}

// async function getObject(db: IDBDatabase, key: string): Promise<string | PersistedBlob | null> {
//   const request = getObjectStore(db).get(key);
//   const data = await new DBPromise<DBObject | undefined>(request).toPromise();
//   return data === undefined ? null : data.value;
// }

async function getAllObjects(db: IDBDatabase): Promise<unknown> {
  const request = getObjectStore(db, true).getAll();
  const data = await new DBPromise<DBObject | undefined>(request).toPromise();
  return data;
}

export async function putObject(
  db: IDBDatabase,
  key: string,
  value: PersistenceValue
): Promise<void> {
  const getRequest = getObjectStore(db, true).get(key);
  const data = await new DBPromise<DBObject | null>(getRequest).toPromise();
  if (data) {
    data.value = value as PersistedBlob;
    const request = getObjectStore(db, true).put(data);
    return new DBPromise<void>(request).toPromise();
  } else {
    const request = getObjectStore(db, true).add({
      [DB_DATA_KEYPATH]: key,
      value
    });
    return new DBPromise<void>(request).toPromise();
  }
}

async function putMultipleObjects(db: IDBDatabase, entries: DBObject[]) {
  if (entries && entries.length > 0) {
    await asyncForEach(entries, async ({ fbase_key, value }) => {
      await putObject(db, fbase_key, value);
    });
  }
}

// async function set(key: string, value: PersistenceValue): Promise<void> {
//   await runDatabaseOperationWithRetries((db: IDBDatabase) => putObject(db, key, value));
// }

// async function get<T extends PersistenceValue>(key: string): Promise<T | null> {
//   const obj = (await runDatabaseOperationWithRetries((db: IDBDatabase) => getObject(db, key))) as T;
//   return obj;
// }

async function setMultiple(entries: DBObject[]): Promise<void> {
  await runDatabaseOperationWithRetries((db: IDBDatabase) => putMultipleObjects(db, entries));
}

async function getAll<T extends PersistenceValue>(): Promise<T | null> {
  const obj = (await runDatabaseOperationWithRetries((db: IDBDatabase) => getAllObjects(db))) as T;
  return obj;
}

export async function getFirebaseIndexedDBValues(): Promise<string | PersistedBlob | null> {
  return await getAll();
}

export async function restoreFirebaseLocalStorageCache(entries: DBObject[]): Promise<void> {
  return await setMultiple(entries);
}

const pollInterval = 5000;
export function startWatchingFirebaseIndexedDBAndCacheItToLocalStorage(): void {
  let localCache: string;

  function checkIndexedDBForChanges() {
    void getFirebaseIndexedDBValues()
      .then(entries => {
        if (entries) {
          const stringifiedEntries = JSON.stringify(entries);
          if (stringifiedEntries !== localCache) {
            localCache = stringifiedEntries;
            window.localStorage.setItem(
              INDEXED_DB_LOCAL_STORAGE_AUTH_STASH_KEY,
              stringifiedEntries
            );
          }
          setTimeout(checkIndexedDBForChanges, pollInterval);
        }
      })
      .catch(error => {
        log.error("Error encountered while checking for Firebase Auth IndexedDB Cache", error);
      });
  }
  void checkIndexedDBForChanges();
}

export async function restoreLocalStorageState(
  localStorageState: Dictionary<string>
): Promise<void> {
  try {
    await asyncForEach(Object.entries(localStorageState), async ([key, value]) => {
      if (value) {
        window.localStorage.setItem(key, value);
        if (key === INDEXED_DB_LOCAL_STORAGE_AUTH_STASH_KEY) {
          const firebaseStateCache = JSON.parse(value) as DBObject[];
          log.info(
            "found cached authentication state from IndexedDB in localStorage, restoring to IndexedDB",
            firebaseStateCache
          );
          await restoreFirebaseLocalStorageCache(firebaseStateCache);
          log.info("Restored to IndexedDB!");
        }
      }
    });
  } catch (error) {
    log.error("Problem encountered while restore localStorage state", error);
  }
}
