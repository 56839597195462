import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { log } from "@/helpers/logger";

const KEYCHAIN_KEY = "InventoryShield";

async function isAvailable(useFallback: boolean = true): Promise<boolean> {
  return (await NativeBiometric.isAvailable({ useFallback })).isAvailable;
}

async function hasCredentialsStored(): Promise<boolean> {
  if (!(await isAvailable())) {
    return false;
  }

  try {
    await NativeBiometric.getCredentials({ server: KEYCHAIN_KEY });
    return true;
  } catch (error) {
    log.warn("Credentials don't exist", error);
    return false;
  }
}

async function saveCredentials(username: string, password: string): Promise<void> {
  // Seems to be necessary for some issues seen on iOS 17 - https://github.com/Cap-go/capacitor-native-biometric/issues/5
  await deleteCredentials();

  await NativeBiometric.setCredentials({
    username,
    password,
    server: KEYCHAIN_KEY
  });
}

async function verifyIdentity(options: Biometric.AuthOptions): Promise<void> {
  return await NativeBiometric.verifyIdentity(options);
}

async function getCredentials(): Promise<Biometric.Credentials> {
  return await NativeBiometric.getCredentials({ server: KEYCHAIN_KEY });
}

async function deleteCredentials(): Promise<void> {
  await NativeBiometric.deleteCredentials({ server: KEYCHAIN_KEY });
}

export const biometric: Biometric.Implementation = {
  isAvailable,
  hasCredentialsStored,
  saveCredentials,
  getCredentials,
  deleteCredentials,
  verifyIdentity
};
