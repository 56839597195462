import "./polyfill";
import { app, biometric, device, platform } from "@/capacitorPlugins";
import { liveUpdates } from "@/capacitorPlugins/liveUpdates";
import { log, LogLevel } from "@/helpers/logger";

async function startMobile(): Promise<void> {
  const appInfo: AppInfo = {
    device: undefined as DeviceInfo | undefined,
    build: undefined as BuildInfo | undefined,
    biometricAuthentication: undefined as BiometricAuthStatus | undefined,
    visualViewport: undefined as AppVisualViewport | undefined
  };

  if (device) {
    appInfo.device = await device.getInfo();
    log.debug("DEVICE DETAIL", appInfo.device);
  }

  if (app) {
    log.debug("BUILD INFO", app.getInfo());
    appInfo.build = await app.getInfo();
    await liveUpdates.initializeLiveUpdates();
  }

  const biometricAuthAvailable = await biometric.isAvailable();
  if (biometricAuthAvailable) {
    appInfo.biometricAuthentication = {
      isAvailable: biometricAuthAvailable,
      hasCredentialsStoredInKeyChain: await biometric.hasCredentialsStored()
    };
  }

  if (window.visualViewport) {
    appInfo.visualViewport = {
      height: window.visualViewport.height,
      width: window.visualViewport.width
    };
  }
  window.APP_INFO = appInfo;
  // if (message.localStorageState) {
  //   await restoreLocalStorageState(message.localStorageState);
  // }
  import("./launchApplication");
}

if (!import.meta.env.VUE_APP_VERSION) {
  log.setLevel(LogLevel.trace);
  log.info("We are not in production so set logging to `trace`");
}

if (platform.isMobile) {
  void startMobile();
} else {
  import("./launchApplication");
}
